<style>
  .noshadow {
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
  }
</style>
<script>
import { VclBulletList, VclFacebook, VclCode, VclList } from 'vue-content-loading';

import CopyableText from "@/components/CopyableText";
import {HalfCircleSpinner} from "epic-spinners";
import {WorkerState} from "@/enums";

export default {
  props: ['server_id', 'options'],
  components: {
    // eslint-disable-next-line vue/no-unused-components
    CopyableText,
    VclCode,
    VclList,
    VclBulletList,
    HalfCircleSpinner
  },
  computed: {
    availablePeriods() {
      let periods = [];
      this.available_months.forEach((period) => {
        periods.push({
          text: `${new Date(2000, period.month-1, 1).toLocaleString('default', { month: 'long' })} ${period.year}`,
          value: `${period.year}-${period.month}`
        });
      });
      return periods;
    }
  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    gryphonChartOptions() {
      return {}
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/overview`, {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.info   = data.info;
          this.server = data.server;
          this.deployment = data.deployment;

          this.influx = data.charts.influx;
          this.unique = data.charts.unique;

          if(data.charts.retention.data === true) {
            this.playerRetention.series.push(data.charts.retention.relative);
          }
          this.retention = data.charts.retention;
          this.queuepriority = data.queuepriority;
          this.whitelist = data.whitelist;
          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        });
    },
    getPlayerChartData: function() {
      let url = new URL(process.env.VUE_APP_ROOT_API + `v1/server/${this.server_id}/historicPlayerData`);
      url.searchParams.append('month', this.players.month);
      url.searchParams.append('year', this.players.year);
      url.searchParams.append('day', this.players.day);
      fetch(url, {credentials: 'include'})
          .then(response => {
            if(response.ok){
              return response.json();
            } else {
              throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
            }
          })
          .then(data => {
            if(data.available_days !== null) this.available_days = data.available_days;
            if(data.available_months !== null) this.available_months = data.available_months;
            this.$refs.playerCount.updateSeries(data.series);
            //this.playerCount.series = data.series;
            //this.playerCount.chart.xaxis.categories = data.labels;
          })
          .catch(error => {
            console.log(error);
          });
    },
    playersChartPeriodSelect() {
      this.players.month = Number.parseInt(this.players.period.split('-')[1]);
      this.players.year = Number.parseInt(this.players.period.split('-')[0]);
      this.players.day = -1;
      this.getPlayerChartData();
    }
  },
  mounted() {
    this.getData();
    this.getPlayerChartData();
  },
  data() {
    return {
      WorkerState:WorkerState,
      ready: false,
      error: false,
      info: null,
      deployment: null,
      server: null,
      queuepriority: null,
      whitelist: null,
      retention: {
        data: null,
        display: 0,
        relative: 0
      },
      influx: {
        data: null,
        display: 0,
        relative: 0
      },
      unique: {
        data: null,
        display: 0,
        relative: 0
      },
      available_days: null,
      available_months: [{year: new Date().getFullYear(), month: new Date().getMonth()+1}],
      players: {
        data: null,
        period: `${new Date().getFullYear()}-${new Date().getMonth()+1}`,
        month: new Date().getMonth()+1,
        year: new Date().getFullYear(),
        day: -1
      },
      playerCount: {
        series: [], /* {
          name: 'Players',
          data: [],
        }
        */
        chart: {
          noData: {
            text: 'Loading...'
          },
          chart: {
            height: 320,
            type: 'line',
            toolbar: 'false',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 18,
              left: 7,
              blur: 8,
              opacity: 0.2
            },
          },
          dataLabels: {
            enabled: false
          },
          colors: ['#556ee6', '#c3cbe4'],
          stroke: {
            curve: 'smooth',
            width: 3,
          },
          xaxis: {
            type: 'category',
            categories: []
          }
        }
      },
      playerRetention: {
        series: [],
        chart: {
          chart: {
            height: 200,
            type: "radialBar",
            offsetY: -10,
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 135,
              dataLabels: {
                name: {
                  fontSize: "13px",
                  color: undefined,
                  offsetY: 60,
                },
                value: {
                  offsetY: 22,
                  fontSize: "16px",
                  color: undefined,
                  formatter: function (val) {
                    return val + "%";
                  },
                },
              },
            },
          },
          colors: ["#556ee6"],
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              shadeIntensity: 0.15,
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 50, 65, 91],
            },
          },
          stroke: {
            dashArray: 3,
          },
          labels: [""],
        }
      }
    }
  }
};
</script>

<template>
  <div>
    <template v-if="ready">
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <template v-if="server.worker.state === WorkerState.ERROR && server.worker.wrong_password">
                    <div class="alert text-center alert-danger">
                      <h4 class="mb-0 text-uppercase" style="color: unset;">
                        <i class="fas fa-exclamation-triangle me-2"></i>
                        {{ $t('server.view.overview.errors.password') }}
                        <i class="fas fa-exclamation-triangle me-2"></i>
                      </h4>
                    </div>
                  </template>
                  <template v-else-if="server.worker.state === WorkerState.ERROR && server.worker.gameserver_offline">
                    <div class="alert text-center alert-danger">
                      <h4 class="mb-0 text-uppercase" style="color: unset;">
                        {{ $t('server.view.overview.errors.offline') }}
                      </h4>
                    </div>
                  </template>
                  <template v-else-if="server.worker.access_restricted">
                    <div class="alert text-center alert-warning">
                      <h4 class="mb-0 text-uppercase" style="color: unset;">
                        {{ $t('server.view.overview.errors.restricted') }}
                      </h4>
                    </div>
                  </template>
                  <div class="row">
                    <div class="col-12">
                      <h3>{{ server.name }}</h3>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col-lg-3 col-sm-12">
                      <div class="card mini-stats-wid noshadow">
                        <div class="card-body">
                          <div class="media">
                            <div class="media-body">
                              <h6 class="text-muted fw-medium mb-2 text-uppercase">
                                {{ $t('server.view.overview.playerdb') }}
                              </h6>
                              <h4 class="mb-0">
                                {{ $n(info.playerdb) }}
                              </h4>
                            </div>
                            <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                              <span class="avatar-title">
                                <i class="font-size-24 fad fa-users"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-sm-12">
                      <div class="card mini-stats-wid noshadow">
                        <div class="card-body">
                          <div class="media">
                            <div class="media-body">
                              <h6 class="text-muted fw-medium mb-2 text-uppercase">
                                {{ $t('server.view.overview.region') }}
                              </h6>
                              <h4 class="mb-0">
                                <template v-if="deployment.continent !== '?'">
                                  {{ deployment.continent }}
                                  <flag iso="eu" v-if="deployment.continent === 'EU' || deployment.country_code === 'DE'"/>
                                  <flag iso="ca" v-else-if="deployment.continent === 'NA' && deployment.country_code === 'CA'"/>
                                  <flag iso="us" v-else-if="deployment.continent === 'NA' && deployment.country_code === 'US'"/>
                                  <flag iso="sg" v-else-if="deployment.continent === 'AS' || deployment.country_code === 'SG'"/>
                                  <flag iso="au" v-else-if="deployment.continent === 'OC' || deployment.country_code === 'AU'"/>
                                </template>
                                <template v-else>
                                  Deploying...
                                </template>
                              </h4>
                            </div>
                            <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                              <span class="avatar-title">
                                <i class="font-size-24 fad fa-globe"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-sm-12">
                      <div class="card mini-stats-wid noshadow">
                        <div class="card-body">
                          <div class="media">
                            <div class="media-body">
                              <h6 class="text-muted fw-medium mb-2 text-uppercase">
                                {{ $t('server.view.overview.rank') }}
                              </h6>
                              <h4 class="mb-0">
                                <template v-if="(info.rank === null || info.rank === -1)">
                                  Unrated
                                </template>
                                <template v-else>
                                  {{ $n(info.rank) }}
                                  <small class="text-muted" style="font-size: 12px;">CFTools Cloud rating</small>
                                </template>
                              </h4>
                            </div>
                            <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                              <span class="avatar-title">
                                <i class="font-size-24 fad fa-chart-line"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-sm-12">
                      <div class="card mini-stats-wid noshadow">
                        <div class="card-body">
                          <div class="media">
                            <div class="media-body">
                              <h6 class="text-muted fw-medium mb-2 text-uppercase">
                                {{ $t('server.view.overview.mod_complexity.title') }}
                              </h6>
                              <h4 class="mb-0">
                                <template v-if="(info.mod_complexity === null || info.mod_complexity <= 1)">
                                  N/A
                                  <i class="fad fa-info-circle small" v-b-tooltip.hover :title="$t('server.view.overview.mod_complexity.description')"/>
                                </template>
                                <template v-else>
                                  <div>
                                    <!-- Averages 01.09.2021 -->
                                    <span :class="{
                                    'text-success': info.mod_complexity < 2400,
                                    'text-primary': info.mod_complexity >= 2400 && info.mod_complexity < 3200,
                                    'text-warning': info.mod_complexity >= 3200 && info.mod_complexity < 4000,
                                    'text-danger': info.mod_complexity >= 4000
                                    }">
                                      {{ $n(info.mod_complexity) }}
                                    </span>
                                    <span v-if="info.mod_complexity >= 4000" class="font-size-14 small text-muted">
                                      {{ $t('server.view.overview.mod_complexity.danger') }}
                                    </span>
                                    <span v-else-if="info.mod_complexity >= 3200" class="font-size-14 small text-muted">
                                      {{ $t('server.view.overview.mod_complexity.warning') }}
                                    </span>
                                    <span v-else-if="info.mod_complexity >= 2400" class="font-size-14 small text-muted">
                                      {{ $t('server.view.overview.mod_complexity.average') }}
                                    </span>
                                    <span v-else-if="info.mod_complexity > 1" class="font-size-14 small text-muted">
                                      {{ $t('server.view.overview.mod_complexity.good') }}
                                    </span>
                                    <i class="fad fa-info-circle small" v-b-tooltip.hover :title="$t('server.view.overview.mod_complexity.description')"/>
                                  </div>
                                </template>

                              </h4>
                            </div>
                            <div class="avatar-sm align-self-center mini-stat-icon rounded-circle bg-primary">
                              <span class="avatar-title">
                                <i class="font-size-24 fad fa-wrench"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row h-100">
                    <div class="col-lg-4 col-sm-12" style="margin-top: auto !important; margin-bottom: auto !important;">
                      <div class="card w-100 noshadow">
                        <div class="card-body">
                          <h5 class="card-title mb-4">
                            {{ $t('server.view.overview.retention.title') }}

                          </h5>
                          <div class="row">
                            <div class="col-sm-6">
                              <template v-if="retention.data === null">
                                <half-circle-spinner
                                    :animation-duration="1200"
                                    :size="36"
                                    class="align-middle d-inline-block"
                                />
                              </template>
                              <template v-else-if="retention.data === false">
                                <span class="badge badge-warning font-size-18 text-black">
                                  {{ $t('server.view.overview.none') }}
                                </span>
                              </template>
                              <template v-else>
                                <h3>
                                  <i class="fad fa-users"/> {{ $n(retention.display) }}
                                </h3>
                                <p class="text-muted">
                                  <span class="mr-2" :class="{'text-success': retention.relative > 0, 'text-danger': retention.relative < 0}">
                                    {{ $n(retention.relative) }} %
                                    <i class="far" :class="{'fa-arrow-up': retention.relative > 0, 'fa-arrow-down': retention.relative < 0}"></i>
                                  </span>
                                </p>
                              </template>

                              <div class="mt-4">
                                <span class="text-muted">{{ $t('server.view.overview.retention.period') }}</span>
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <!-- Chart -->
                              <apexchart
                                  class="apex-charts"
                                  type="radialBar"
                                  height="200"
                                  dir="ltr"
                                  :series="playerRetention.series"
                                  :options="playerRetention.chart"
                                  v-if="retention.data === true"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-1"></div>
                    <div class="col-lg-3 col-sm-12" style="margin-top: auto !important; margin-bottom: auto !important;">
                      <div class="card noshadow">
                        <div class="card-body">
                          <div class="d-flex align-items-center mb-3">
                            <h5 class="font-size-14 mb-0">{{ $t('server.view.overview.influx.title') }}</h5>
                          </div>
                          <div class="text-muted mt-4">
                            <template v-if="influx.data === null">
                              <half-circle-spinner
                                  :animation-duration="1200"
                                  :size="36"
                                  class="align-middle d-inline-block"
                              />
                            </template>
                            <template v-else-if="influx.data === false">
                                <span class="badge badge-warning font-size-18 text-black">
                                  {{ $t('server.view.overview.none') }}
                                </span>
                            </template>
                            <template v-else>
                              <h4>
                                {{ $n(influx.display) }}
                                <i class="mdi ml-1 " :class="{
                                  'mdi-chevron-up': influx.relative > 0,
                                  'mdi-chevron-down': influx.relative < 0,
                                  'text-success': influx.relative > 0,
                                  'text-danger': influx.relative < 0
                                }"></i>
                              </h4>
                              <div class="d-flex">
                                <span class="badge font-size-12" :class="{'badge-soft-success': influx.relative > 0, 'badge-soft-danger': influx.relative < 0}">
                                  <template v-if="influx.relative > 0">
                                    +
                                  </template>
                                  {{ $n(influx.relative) }}%
                                </span>
                                <span class="ml-2 text-truncate">{{ $t('server.view.overview.influx.period') }}</span>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 col-sm-12" style="margin-top: auto !important; margin-bottom: auto !important;">
                      <div class="card noshadow">
                        <div class="card-body">
                          <div class="d-flex align-items-center mb-3">
                            <h5 class="font-size-14 mb-0">{{ $t('server.view.overview.unique.title') }}</h5>
                          </div>
                          <div class="text-muted mt-4">
                            <template v-if="unique.data === null">
                              <half-circle-spinner
                                  :animation-duration="1200"
                                  :size="36"
                                  class="align-middle d-inline-block"
                              />
                            </template>
                            <template v-else-if="unique.data === false">
                                <span class="badge badge-warning font-size-18 text-black">
                                  {{ $t('server.view.overview.none') }}
                                </span>
                            </template>
                            <template v-else>
                              <h4>
                                {{ $n(unique.display) }}
                                <i class="mdi ml-1 " :class="{
                                  'mdi-chevron-up': unique.relative > 0,
                                  'mdi-chevron-down': unique.relative < 0,
                                  'text-success': unique.relative > 0,
                                  'text-danger': unique.relative < 0
                                }"></i>
                              </h4>
                              <div class="d-flex">
                                <span class="badge font-size-12" :class="{'badge-soft-success': unique.relative > 0, 'badge-soft-danger': unique.relative < 0}">
                                  <template v-if="unique.relative > 0">
                                    +
                                  </template>
                                  {{ $n(unique.relative) }}%
                                </span>
                                <span class="ml-2 text-truncate">{{ $t('server.view.overview.unique.period') }}</span>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row justify-content-center">
                    <div class="col-lg-3">
                      <div class="text-muted">
                        <div class="mb-4">
                          <h4>{{ $t('server.view.overview.players.title') }}</h4>
                        </div>
                        <div>
                          <div>
                            <div class="input-group input-group-sm">
                              <b-form-select class="custom-select custom-select-sm" :options="availablePeriods" v-model="players.period" v-on:change="playersChartPeriodSelect">

                              </b-form-select>
                              <div class="input-group-append">
                                <label class="input-group-text">
                                  {{ $t('server.view.overview.players.month') }}
                                </label>
                              </div>
                            </div>
                          </div>
                          <div class="mt-2">
                            <div class="input-group input-group-sm">
                              <select class="custom-select custom-select-sm" v-model="players.day" v-on:change="getPlayerChartData">
                                <option selected value="-1">{{ $t('server.view.overview.players.total') }}</option>
                                <template v-if="available_days !== null">
                                  <option v-for="day in available_days" :key="day">
                                    {{ day }}
                                  </option>
                                </template>
                              </select>
                              <div class="input-group-append">
                                <label class="input-group-text">
                                  {{ $t('server.view.overview.players.day') }}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-7">
                      <apexchart
                          ref="playerCount"
                          class="apex-charts"
                          dir="ltr"
                          height="320"
                          :series="playerCount.series"
                          :options="playerCount.chart"
                      ></apexchart>
                    </div>
                  </div>
                  <div class="row mt-4 justify-content-center">
                    <div class="col-lg-4 col-sm-12" v-if="queuepriority">
                      <div class="card shadow-lg">
                        <div class="card-header text-uppercase h5">
                          <i class="fad fa-shipping-fast"></i>

                          {{ $t('server.view.overview.queuepriority.title') }}
                        </div>
                        <div class="card-body">
                          <h4>
                            {{ $n(queuepriority.active) }} {{ $t('server.view.overview.queuepriority.active') }}
                          </h4>
                          <hr>
                          <div class="row">
                            <div class="col-4">
                              <div>
                                <p class="text-muted text-truncate mb-2">
                                  {{ $t('server.view.overview.queuepriority.permanent') }}
                                </p>
                                <h5 class="mb-0">
                                  {{ $n(queuepriority.permanent) }}
                                </h5>
                              </div>
                            </div>
                            <div class="col-4">
                              <div>
                                <p class="text-muted text-truncate mb-2">
                                  {{ $t('server.view.overview.queuepriority.temporary') }}
                                </p>
                                <h5 class="mb-0">
                                  {{ $n(queuepriority.temporary) }}
                                </h5>
                              </div>
                            </div>
                            <div class="col-4">
                              <div>
                                <p class="text-muted text-truncate mb-2">
                                  {{ $t('server.view.overview.queuepriority.expiration_warning') }}
                                </p>
                                <h5 class="mb-0">
                                  {{ $n(queuepriority.expiration_warning) }}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-sm-12" v-if="whitelist">
                      <div class="card shadow-lg">
                        <div class="card-header text-uppercase h5">
                          <i class="fad fa-clipboard-list-check"></i>

                          {{ $t('server.view.overview.whitelist.title') }}
                        </div>
                        <div class="card-body">
                          <h4>
                            {{ $n(whitelist.active) }} {{ $t('server.view.overview.whitelist.active') }}
                          </h4>
                          <hr>
                          <div class="row">
                            <div class="col-4">
                              <div>
                                <p class="text-muted text-truncate mb-2">
                                  {{ $t('server.view.overview.whitelist.permanent') }}
                                </p>
                                <h5 class="mb-0">
                                  {{ $n(whitelist.permanent) }}
                                </h5>
                              </div>
                            </div>
                            <div class="col-4">
                              <div>
                                <p class="text-muted text-truncate mb-2">
                                  {{ $t('server.view.overview.whitelist.temporary') }}
                                </p>
                                <h5 class="mb-0">
                                  {{ $n(whitelist.temporary) }}
                                </h5>
                              </div>
                            </div>
                            <div class="col-4">
                              <div>
                                <p class="text-muted text-truncate mb-2">
                                  {{ $t('server.view.overview.whitelist.expiration_warning') }}
                                </p>
                                <h5 class="mb-0">
                                  {{ $n(whitelist.expiration_warning) }}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                      <pre>
{{ JSON.stringify(server.raw, null, '\t') }}
                      </pre>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          -->
        </div>
      </div>
    </template>
    <template v-else-if="error">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{$t('error.server.generic.title')}}
              </h5>
            </div>
            <div class="card-body pt-0">
              <h5 class="card-title mt-0"> {{$t('error.server.generic.component')}}</h5>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-11">
          <!-- Content row one -->
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                    <div class="col-lg-4">
                      <VclCode :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4"></VclCode>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <VclBulletList :speed="1" primary="#FFFFFF" secondary="#bdbdbd"></VclBulletList>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card">
                <div class="card-body">
                  <VclList :speed="1" primary="#FFFFFF" secondary="#bdbdbd" class="mt-4 mb-2"></VclList>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
